import { useEffect, useRef } from 'react';

function ModalMounter({ children }) {
  const scrollTopRef = useRef(0);

  useEffect(() => {
    const scrollingElement = document.scrollingElement
      ? document.scrollingElement
      : document.documentElement;
    scrollTopRef.current = scrollingElement.scrollTop;

    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
    document.documentElement.style.height = '100%';
    document.documentElement.style.overflowY = 'scroll';
    document.body.scrollTop = scrollTopRef.current;

    return () => {
      document.body.style.height = '';
      document.body.style.overflow = '';
      document.body.scrollTop = 0;
      document.documentElement.style.height = '';
      document.documentElement.style.overflowY = '';
      scrollingElement.scrollTop = scrollTopRef.current;
    };
  }, []);

  return children;
}

export default ModalMounter;
