import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../hocs/withDictionary';
import useComponentError from '../lib/hooks/component-error';

export function componentError(componentName) {
  function createError(name, message) {
    const dictionaryKey = dictionaryItem(componentName, name, 'Error');
    const error = new Error(message || dictionaryKey);
    error.name = name;
    return error;
  }

  return { createError };
}

export const ErrorContext = createContext();

function ErrorProvider(props) {
  const value = useComponentError();

  return (
    <ErrorContext.Provider value={value} {...props} />
  );
}

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorProvider;
