import styled, { css, keyframes } from 'styled-components';

import detectIE from '../../../lib/detectIE';
import config from '../../../styles/config/theme';

import Icon from '../Icon';
import ButtonCloseComponent from '../Button/ButtonClose';

import { above } from '../../../styles/libs';
import zIndexConfig from '../../../config/zIndex';

const sizes = {
  small: config.MODAL_SIZE_SMALL,
  medium: config.MODAL_SIZE_MEDIUM,
};

export default styled.div`
  position: ${props => (props.visible ? 'relative' : 'fixed')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  z-index: ${({ zIndex }) => zIndex};
`;

export const ModalClose = styled(ButtonCloseComponent)`
  position: absolute;
  text-align: center;
  top: 1.5rem;
  right: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 1;
`;

const ModalOverflowInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const ModalContentInAnimation = keyframes`
  0% {
    transform: translateY(2.5rem);
    opacity: 0;
  }

  66% {
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const inAnimationTiming = 'cubic-bezier(.01,.8,.18,1)';

export const ModalDesktopMarginY = '3rem';
export const ModalPaddingY = '3rem';

export const ModalContent = styled.div`
  width: 100%;
  max-width: ${({ size }) => sizes[size] || sizes.medium}px;
  ${({ minHeight }) => minHeight && css`min-height: ${minHeight};`}
  background: ${({ theme, light }) => (light ? theme.COLOR_WHITE : theme.COLOR_GRAY_LIGHTER)};
  border-radius: 3px;
  padding: ${ModalPaddingY} 2.5rem;
  position: relative;
  z-index: ${zIndexConfig.IMPORTANT_CONTENT};
  will-change: transform, opacity;
  animation: ${ModalContentInAnimation} 0.66s ${inAnimationTiming};

  ${({ theme }) => above(theme.TABLET)`
    margin: ${ModalDesktopMarginY} auto;
  `}

  ${({ size }) => size === 'small' && css`
    text-align: center;
  `}

  ${({ textCentered }) => textCentered && css`
    text-align: center;
  `}

  ${!!detectIE() && `
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const ScrollableModalContent = styled.div`
  margin-top: 0.75rem;
  height: calc(62.5vh - (${ModalPaddingY} + ${ModalPaddingY}));
  ${({ theme }) => above(theme.TABLET)`
    height: auto;
    max-height: calc(75vh - (${ModalDesktopMarginY} + ${ModalDesktopMarginY} + ${ModalPaddingY} + ${ModalPaddingY}));
  `}
  overflow-y: auto;
  padding-right: 0.5rem;

  ${({ showShadow, theme }) => showShadow && css`
    background: linear-gradient(180deg, ${theme.COLOR_GRAY_LIGHTER} 0%, rgba(0, 0, 0, 0.2) 100%);
    background-repeat: no-repeat;
    background-size: 100% 20px;
    background-position: bottom;
  `}
`;

export const ModalFooter = styled.div`
  padding: 2rem 0 0;
  ${({ flex }) => flex && css`
    display: flex;
    align-items: center;
  `}
`;

export const ModalHeader = styled.div``;

export const ModalIcon = styled(Icon)`
  font-size: 3.5rem;
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  margin: 0 auto 1rem;
`;

export const ModalOverflow = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  z-index: ${({ zIndex }) => (zIndex ? zIndex + 1 : 2)};
  will-change: opacity;
  animation: ${ModalOverflowInAnimation} 0.66s ${inAnimationTiming};
`;

export const ModalFlex = styled.div`
  min-width: 100%;
  min-height: 100%;

  ${({ fullscreen }) => !fullscreen && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
