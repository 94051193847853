import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Footer from './Footer';
import Header from './Header';
import { StyleWrapper } from './HeaderAndFooter.style';

function HeaderAndFooterLayout({ isLoggedIn, children, config }) {
  return (
    <>
      <StyleWrapper styles={config.css}>
        <Header {...config.header} isLoggedIn={isLoggedIn} />
      </StyleWrapper>
      {children}
      <StyleWrapper styles={config.css}>
        <Footer {...config.footer} />
      </StyleWrapper>
    </>
  );
}

HeaderAndFooterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    css: PropTypes.string,
    header: PropTypes.shape({}),
    footer: PropTypes.shape({}),
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default memo(HeaderAndFooterLayout);
