import styled from 'styled-components';

export const Section = styled.section`
`;

export const Main = styled.main`
  position: relative;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
