import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalClose, ModalContent } from '../components/ui/Modal';
import LoginPopUp from '../components/PopUp/LoginPopUp';

const PopupsContext = React.createContext();

export const popupKeys = {
  LOGIN_POPUP: 'loginPopup',
};

export const PopupsProvider = ({ children }) => {
  const [activePopups, setActivePopups] = useState([]);

  const validatePopupData = (popupData) => {
    if (!popupData.key) {
      throw Error('PopupsProvider validatePopupData(): popupData does not contain a key');
    }

    if (!Object.values(popupKeys).includes(popupData.key)) {
      throw Error('PopupsProvider validatePopupData(): popupData key is not included in popupKeys');
    }

    return true;
  };

  const isOpen = (key) => !!activePopups.find(popup => popup.key === key);

  const openPopup = (popupData) => {
    if (!validatePopupData(popupData)) return false;

    if (!isOpen(popupData.key)) {
      setActivePopups(popups => [...popups, popupData]);
      return true;
    }

    return false;
  };

  const getPopup = (popupData) => {
    if (!validatePopupData(popupData)) return false;

    if (isOpen(popupData.key)) {
      return activePopups.find(popup => popup.key === popupData.key);
    }

    return false;
  };

  const closePopup = (popupData) => {
    if (!validatePopupData(popupData)) return false;

    if (isOpen(popupData.key)) {
      setActivePopups(popups => popups.filter(popup => popup.key !== popupData.key));
      return true;
    }

    return false;
  };

  const closeAllPopups = () => {
    setActivePopups([]);
  };

  return (
    <PopupsContext.Provider value={{
      popupActions: {
        openPopup,
        closePopup,
        closeAllPopups,
        getPopup,
      },
    }}
    >

      {children}
      <>
        {activePopups.map(popup => (
          // - add new popup variants here
          <Modal active={popup.key === popupKeys.LOGIN_POPUP} overlay key={popup.key}>
            <ModalContent size="small">
              <ModalClose onClick={() => closePopup({ key: popup.key })} />
              <LoginPopUp {...popup} />
            </ModalContent>
          </Modal>
        ))}
      </>
    </PopupsContext.Provider>
  );
};

PopupsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const { PopupsConsumer } = PopupsContext;

export const withPopupsConsumer = (WrapperComponent) => {
  const WithPopupsConsumer = (props) => (
    <PopupsContext.Consumer>
      {({ popupActions }) => (
        <WrapperComponent {...props} popupActions={popupActions} />
      )}
    </PopupsContext.Consumer>
  );

  WithPopupsConsumer.displayName = 'withPopupsConsumer';
  return WithPopupsConsumer;
};
