import theme from '../styles/config/theme';
import { defaultTypes, keys, ids } from '../config/campsiteTypes';

export const parseTypes = (types, configDefaultTypes) => {
  let parsedTypes = configDefaultTypes ?? [];

  if (types) {
    if (typeof (types) === 'string') {
      parsedTypes = [types];
    } else {
      parsedTypes = types;
    }

    parsedTypes = parsedTypes
      .map(type => Number(type))
      .sort();
  }

  return parsedTypes;
};

export const getDefaultTypes = () => defaultTypes;

export function getKeyFromValue(campsiteTypes, value) {
  const { key } = campsiteTypes.find(type => type.value === value);
  return key;
}

export default function CampsiteTypes(campsiteTypes, typeKey) {
  if (typeof campsiteTypes === 'undefined') {
    return {
      id: null,
      text: null,
      color: null,
    };
  }

  if (typeKey === ids.OVERSEAS_TOUR) {
    return {
      id: typeKey,
      text: 'Tour',
      color: theme[`COLOR_CAMPSITE_TYPE_${typeKey}`],
    };
  }

  const campsiteType = campsiteTypes.find(type => type.key === typeKey);

  if (!campsiteType) return {};

  return {
    id: campsiteType.key,
    text: campsiteType.value,
    color: theme[`COLOR_CAMPSITE_TYPE_${typeKey}`],
  };
}

export const CAMC_MEMBER_PRODUCT_CODE = 1;

/**
 * temporary mapping from campsiteType to productType, product type should be provided
 * along side campsiteType from BE to avoid this hard coded mapping.
 */
export function getProductWithCampsiteType(campsiteType, products = []) {
  const ukProduct = products?.find((product) => product.productCode !== CAMC_MEMBER_PRODUCT_CODE);

  const overseasProduct =
    products?.find((product) => product.productCode === CAMC_MEMBER_PRODUCT_CODE);

  if (campsiteType !== undefined) {
    if (campsiteType === ids[keys.OVERSEAS_SITE]) {
      return overseasProduct;
    }
    return ukProduct;
  }

  // Member product by default (OSNV, Crossings)
  return overseasProduct;
}

export function isCampsiteOverseas(campsiteType) {
  return campsiteType === ids.OVERSEAS_SITE;
}

export function getProductCodeByName(productName, products) {
  const defaultProductCode = CAMC_MEMBER_PRODUCT_CODE;
  if (!products || !productName) {
    return defaultProductCode;
  }
  const product = products
    .find(productItem => productItem.productName === productName);

  if (!product) {
    return defaultProductCode;
  }

  return product.productCode;
}

export function getBookableDaysFromToday(campsiteType, products = [], isOverseasQuery = false) {
  const DEFAULT_OVERSEAS_BOOKABLE_DAYS = 2;
  const DEFAULT_UK_BOOKABLE_DAYS = 0;

  // All non-overseas products are UK product
  const UKProduct = products?.find((product) => product.productCode !== CAMC_MEMBER_PRODUCT_CODE);
  const overseasProduct =
    products?.find((product) => product.productCode === CAMC_MEMBER_PRODUCT_CODE);

  const UKProductBookableDays = UKProduct?.minSearchDaysAfterToday ?? DEFAULT_UK_BOOKABLE_DAYS;
  const overseasProductBookableDays =
    overseasProduct?.minSearchDaysAfterToday ?? DEFAULT_OVERSEAS_BOOKABLE_DAYS;

  if (campsiteType !== undefined) {
    if (campsiteType === ids[keys.OVERSEAS_SITE]) {
      return overseasProductBookableDays;
    }
    return UKProductBookableDays;
  }
  return isOverseasQuery ? overseasProductBookableDays : UKProductBookableDays;
}

export const checkToShowPricesAndRatings = (type) => {
  const hideAddressCampsiteTypes = [
    ids.UK_AFFILIATED_SITES,
    ids.CERTIFICATED_LOCATIONS,
  ];

  if (type === undefined) return true;

  return hideAddressCampsiteTypes.includes(type) === false;
};
