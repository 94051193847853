import styled, { css } from 'styled-components';

import { ButtonClose as ButtonCloseComponent, ButtonBordered } from '../ui/Button';
import { buttonBrandCSS } from '../ui/Button/ButtonBrand.style';
import RichTextComponent from '../ui/RichText';
import Icon from '../ui/Icon';
import zIndex from '../../config/zIndex';

export const PopUpWrapper = styled.dialog`
  position: fixed;
  width: ${({ width }) => (width || '26.1rem')};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}` : '')};
  max-width: 90vw;
  margin: 0;
  background: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
  border-radius: 0.3rem;
  padding: 1.5rem 3.5rem 2.5rem;
  border: none;
  text-align: center;
  transform: ${props => (props.visible ? 'scale3d(1, 1, 1) translate3d(-50%, -50%, 0);' : 'scale3d(0.25, 0.25, 0.25)')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: transform 0.4s ease-in-out,
              opacity 0.4s ease-in-out;
  will-change: transform, opacity;
  top: 50%;
  left: 50%;
  z-index: ${zIndex.POPOVER};
`;

export const ButtonClose = styled(ButtonCloseComponent)`
  position: absolute;
  text-align: center;
  top: 1.5rem;
  right: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const AlertIcon = styled(Icon)`
  font-size: 3.5rem;
  color: ${({ theme }) => theme.COLOR_AMBER_RED};
  margin: 1rem auto;
`;

export const QuestionIcon = styled(Icon)`
  font-size: 3.5rem;
  color: ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  margin: 1rem auto;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.375rem;

  ${({ light }) => light && css`
    flex-direction: column;
  `}
`;

export const Action = styled(ButtonBordered)`
  margin-top: 1rem;
  flex: 1;

  ${({ light }) => css`
    &:first-child {
      margin-right: ${light ? '0' : '0.5rem'};
    }

    &:last-child {
      margin-left: ${light ? '0' : '0.5rem'};
    }
  `}
`;

export const ActionBrand = styled(Action)`
  ${buttonBrandCSS}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;

export const Footer = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY};

  ${({ align }) => align && `text-align: ${align};`}
`;

export const RichText = styled(RichTextComponent)`
  width: 100%;
  max-width: 36rem;

  h2 {
    font-size: 1.25rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-weight: bold;
      padding-left: 2rem;
      line-height: 1.5;
      margin: 0.5rem 0;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        background-image: url("/static/images/icons/ListItemTick.svg");
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.5em;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  p {
    font-size: 0.875rem;
    margin: 1.5em 0;
  }
`;
