import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledOverlay from './Overlay.style';

const propTypes = {
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
};

const defaultProps = {
  onClick() { },
  zIndex: 1,
};

function Overlay({ onClick, zIndex }) {
  return (
    <StyledOverlay onClick={onClick} zIndex={zIndex} />
  );
}

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;

export default memo(Overlay);
