export const USER_LOCATION_PERMISSIONS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  NOT_KNOWN: 'NOT_KNOWN',
};

export const GEOLOCATION_FAILURE_CODES = {
  DENIED: 1,
  UNAVAILABLE: 2,
  TIMEOUT: 3,
};

export const BROWSER_PERMISSIONS = {
  GRANTED: 'granted',
  DENIED: 'denied',
  PROMPT: 'prompt',
};

export function createQueryString(query) {
  return Object.keys(query)
    .map(key => `${key}=${query[key]}`)
    .join('&');
}

export function appendQuery(url = window.location.href, query) {
  const queryString = createQueryString(query);
  const urlContainsQuery = url.includes('?');
  const seperator = urlContainsQuery ? '&' : '?';

  return `${url}${seperator}${queryString}`;
}

export default { appendQuery, createQueryString };
