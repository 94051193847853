import React, { Children, PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { ModalMounter, Overlay } from '.';
import Popper from '../Popper';

import StyledModal, { ModalFlex, ModalOverflow } from './Modal.style';
import zIndexConfig from '../../../config/zIndex';

class Modal extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    fullscreen: PropTypes.bool,
    overlay: PropTypes.bool,
    zIndex: PropTypes.number,
    onClickOutside: PropTypes.func,
  };

  static defaultProps = {
    active: false,
    className: '',
    fullscreen: false,
    overlay: false,
    zIndex: zIndexConfig.MODAL,
    onClickOutside: undefined,
  };

  state = {
    visible: false,
    overlay: false,
  }

  childNode = React.createRef();

  render() {
    const {
      active, children, className, fullscreen, overlay, zIndex, onClickOutside,
    } = this.props;

    return (
      <Popper open={active}>
        {({ ref }) => (
          <StyledModal
            active={active}
            className={className}
            visible={this.state.visible}
            overlay={active || this.state.overlay}
            ref={ref}
            zIndex={zIndex}
          >
            <ModalMounter>
              <ModalOverflow>
                <ModalFlex
                  fullscreen={fullscreen}
                  onClick={() => onClickOutside && onClickOutside()}
                >
                  {Children.map(children, child => cloneElement(child, {
                    active,
                    visible: this.state.visible,
                    onClick: e => e.stopPropagation(),
                  }))}
                </ModalFlex>
              </ModalOverflow>
            </ModalMounter>

            {overlay &&
              <Overlay />
            }
          </StyledModal>
        )}
      </Popper>
    );
  }
}

export default Modal;
