import styled, { css } from 'styled-components';

import IconComponent from '../../ui/Icon';
import zIndex from '../../../config/zIndex';
import withDictionary from '../../../hocs/withDictionary';

function addStyles({ styles = '' }) {
  return css`
    ${styles}
  `;
}

const HeaderWithAddStyles = styled.header(addStyles);
// override incoming CAMC stylesheet, our header z-index works a
// little differently with all our popups and modals
export const Header = styled(HeaderWithAddStyles)`
  z-index: ${zIndex.HEADER_OVERRIDE} !important;
`;

export const A = styled.a(addStyles);
export const Button = styled.button(addStyles);
export const Div = styled.div(addStyles);
export const Footer = styled.footer(addStyles);
export const Form = styled.form(addStyles);
export const Icon = styled(IconComponent)(addStyles);
export const Img = styled.img(addStyles);
export const Input = styled.input(addStyles);
export const Label = styled.label(addStyles);
export const Span = styled.span(addStyles);
export const StyleWrapper = styled.div(addStyles);

// override CAMC styles and provide IBE accordion functionality
export const Nav = styled.nav`
  width: 0 !important;
  z-index: ${zIndex.MOBILE_NAV};
  @media print, screen and (max-width: 59.75em) {
    ${({ isOpen, theme }) => (isOpen ? (
    `transform: translateX(0px);
      display: block !important;
      width: 100% !important;
      opacity: 1;
      transition:
        opacity 0.5s ease-out,
        transform 0s ease-out 0s;
        width 0s ease-out 0s`
  ) : (
    `transform: translateX(-100%);
      opacity: 0;
      transition:
        opacity 0.5s ease-out 0s,
        transform 0s ease-out 0.5s,
        width 0s ease-out 0.5s;`
  ))}
  }
  @media print, screen and (min-width: 59.75em) {
    display: block !important;
    transform: translateX(0px);
    width: 100% !important;
    opacity: 1;
    transition: opacity 0s ease-out, transform 0s ease-out 0s;
  }
`;

export const Text = withDictionary(styled.p``, { name: 'Text' });

// override CAMC styles and provide IBE accordion functionality
export const AccordionButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  text-align: left;

  @media screen and (min-width: 481px) {
    h2 {
      line-height: 60px!important;
      padding-left: 3px;
      vertical-align: middle;
    }
  }
`;

// override CAMC styles and provide IBE accordion functionality
export const AccordionContent = styled.div`
  @media only screen and (max-width: 767px) {
    display: block !important;
    height: 0;
    padding-bottom: 0 !important;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      height: 22px;
    }
    transition: height 0.33s ease-in-out;
    will-change: height;
  }
`;

// override CAMC styles and provide IBE accordion functionality
export const AccordionHeading = styled.h2`
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1em;
    height: 1.5em;
    font-size: 18px;
    font-weight: 400;
    transition: transform 0.5s ease 0.33s !important;
    ${({ isOpen }) => (isOpen ? css`
      transform: rotate(180deg);
    ` : '')}
  }
`;

export default { Div };
