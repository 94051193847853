import React, { memo } from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

import { isDesktopOrSmaller } from '../../../lib/helpers/layout';

// eslint-disable-next-line import/no-cycle
import { handleLogOutAndReturnURL } from './Header';

import { Div, Nav } from './HeaderAndFooter.style';
import LOG_OUT from '../graphql/logOut.gql';

function setOverflowStyle() {
  return {
    display: 'block',
    maxHeight: '100%',
    overflowY: 'auto',
  };
}

function setDisplay(menu, selectedMenus) {
  if (!selectedMenus.includes(menu)) return {};

  const style = {
    display: 'block',
    transform: 'translate3d(0, 0, 0)',
  };

  if (window.innerWidth > 767) return style;

  return {
    ...style,
    ...setOverflowStyle(),
  };
}

function arrowRightIcon({ columns = [], linkItems = [] }) {
  if (!isDesktopOrSmaller() || (!columns.length && !linkItems.length)) return null;
  return <span className="icon fas fa-chevron-right" />;
}

arrowRightIcon.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  linkItems: PropTypes.arrayOf(PropTypes.shape({})),
};

arrowRightIcon.defaultProps = {
  columns: [],
  linkItems: [],
};

function Navigation({
  isOpen,
  links,
  menus,
  onToggleOpen,
}) {
  let index = 0;

  const linksAboveMenu = links.filter(link => !link.showInMobileMenuBelow);
  const linksBelowMenu = links.filter(link => !!link.showInMobileMenuBelow);

  return (
    <Nav
      className="pnav type--dreadnaught clearfix"
      data-pnav=""
      data-require="src/navigation"
      id="navigation"
      isOpen={isOpen}
    >
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div className="pnav__bg" data-nav-bg="" />

      <Div className="pnav__level pnav__level--1" style={setDisplay(0, [0])}>
        <Mutation mutation={LOG_OUT}>
          {logOut => (
            <ul className="pnav__items">
              <li className="pnav__item pnav__item--hidden">
                <a href="/" className="pnav__link pnav__link--l1 pnav__link--home" data-pnav-level="0">
                  Home
                  <span className="icon icon--right-arrow-big" />
                </a>
              </li>

              {linksAboveMenu.map(link => (
                <li key={link.href} className="pnav__item pnav__item--hidden">
                  <a
                    href={link.href}
                    className="pnav__link pnav__link--l1"
                    data-pnav-level="1"
                    {...(link.logOutBeforeRedirect
                      ? { onClick: handleLogOutAndReturnURL(logOut) }
                      : {}
                    )}
                  >
                    {link.text}
                    {arrowRightIcon(link)}
                  </a>
                </li>
              ))}

              {menus.map((menu, menuItemIndex, array) => {
                index += 1;
                const menuIndex = index;

                return menu && (
                  <li
                    className={`pnav__item pnav__item--tray${menuItemIndex + 1 === array.length ? ' pnav__item--last-desktop' : ''}`}
                    data-nav={menu.url}
                    key={menuIndex}
                  >
                    <a
                      href={menu.url}
                      className="pnav__link pnav__link--l1"
                      data-pnav-level="1"
                    >
                      <span className="pnav__link-text">{menu.text}</span>
                    </a>

                  </li>
                );
              })}

              {linksBelowMenu.map(link => (
                <li key={link.href} className="pnav__item pnav__item--hidden">
                  <a
                    href={link.href}
                    className="pnav__link pnav__link--l1"
                    data-pnav-level="1"
                    {...(link.logOutBeforeRedirect
                      ? { onClick: handleLogOutAndReturnURL(logOut) }
                      : {}
                    )}
                  >
                    {link.text}
                    {arrowRightIcon(link)}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </Mutation>
      </Div>
    </Nav>
  );
}

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ).isRequired,
  menus: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ).isRequired,
  onToggleOpen: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  isOpen: false,
};

export default memo(Navigation);
