import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: -12rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${({ zIndex }) => zIndex};
  pointer-events: auto;
`;
