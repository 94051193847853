import styled from 'styled-components';

export default styled.div`
 position: relative;
`;

export const LazyAnchor = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
`;
