import styled, { css } from 'styled-components';
import detectIE from '../../../lib/detectIE';
import zIndex from '../../../config/zIndex';
import { below } from '../../../styles/libs';

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  // Use dynamic units and fallback to vh if not supported
  min-height: 100vh;
  min-height: 100dvh;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  ${({ theme }) => css`
    ${below(theme.DESKTOP)`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden auto;
    `}
  `}
`;

export const Section = styled.section`
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.SECTION_BACKGROUND_COLOR};;
`;

export const Main = styled.main`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 10vh;

  ${({ theme }) => css`
    ${below(theme.DESKTOP)`
      height: initial;
      min-height: initial;
    `}
  `}

  ${!!detectIE() && `
    height: 100vh;
    height: auto;
  `}

  /**
   * CSS hack to create a element boundaries for 'position: fixed' instead of the body
   * Will need to add this if it's decided to restrict modal overlays between the header and footer
   * Additional work will be needed to make the basket and modals sticky again
  */
  /* transform: translate3d(0, 0, 0); */

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: ${zIndex.MAIN_TOP_GRADIENT};
    ${({ isBookingWidget }) => !isBookingWidget && css`
      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
    `}
  }
`;
