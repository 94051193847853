/* eslint-disable */

const bcLoad = () => {
  if (window.bcLoaded) return;

  window.bcLoaded = true;

  const vms = window.document.createElement('script');

  vms.type = 'text/javascript';

  vms.async = true;

  vms.src = `${window.document.location.protocol === 'https:' ? 'https://' : 'http://'}vmss-eu.boldchat.com/aid/117815200198282976/bc.vms4/vms.js`;

  const s = window.document.getElementsByTagName('script')[0];

  s.parentNode.insertBefore(vms, s);
};

export default function LiveChat() {
  window._bcvma = window._bcvma || [];

  window._bcvma.push(['setAccountID', '117815200198282976']);

  window._bcvma.push(['setParameter', 'WebsiteID', '118605065429718542']);

  window._bcvma.push(['addFloat',
    { type: 'chat', id: '118605065022801840' },
  ]);

  window._bcvma.push(['pageViewed']);

  if (window.pageViewer && pageViewer.load) {
    pageViewer.load();
  } else if (document.readyState === 'complete') {
    bcLoad();
  } else if (window.addEventListener) {
    window.addEventListener('load', bcLoad, false);
  } else {
    window.attachEvent('onload', bcLoad);
  }
}
