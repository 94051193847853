import React, { Fragment, useState, useEffect } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import getConfig from 'next/config';

import { dictionaryItem } from '../../hocs/withDictionary';
import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';

import Title from '../ui/Title';
import Text from '../ui/Text';

import svgError from '../../static/images/icons/Error.svg';
import isLoggedIn from '../../lib/isLoggedIn';

import {
  AlertIcon,
  Actions,
  Action,
  Footer,
  RichText,
} from './PopUp.styles';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_USER_STATUS from '../../config/graphql/getUser';
import UPDATE_QUOTE from '../Quote/graphql/updateQuote';
import { updateQuoteCache } from '../../resolvers/quote';
import testingAttr from '../../lib/testingAttr';
import IbePropTypes from '../../IbePropTypes';
import FetchPolicy from '../../constants/FetchPolicy';
import { navigateTo, POST_TYPES } from '../../lib/helpers/navigation';
import { appendQuery } from '../../lib/location';
import { getProductCodeByName } from '../../lib/campsiteTypes';

const propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  userStatus: PropTypes.shape({
    loading: PropTypes.bool,
    user: PropTypes.shape(IbePropTypes.user),
    refetch: PropTypes.func,
  }),
  config: PropTypes.shape({
    configuration: PropTypes.shape(IbePropTypes.configuration),
  }),
  redirectTo: PropTypes.string,
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  title: PropTypes.string,
  prompt: PropTypes.string,
  showMembership: PropTypes.bool,
  quote: PropTypes.shape(IbePropTypes.quote),
  updateQuote: PropTypes.func,
  closePopup: PropTypes.func.isRequired,
};

const defaultProps = {
  userStatus: {
    loading: false,
    user: {
      membershipExpired: false,
      canProceedToBooking: false,
    },
    refetch: () => {},
  },
  config: {
    configuration: {
      joinNowLink: '',
    },
  },
  redirectTo: '',
  title: '',
  prompt: '',
  showMembership: true,
  quote: {},
  updateQuote: () => {},
};

function handleLoginButton(client, router, loginUrlLink, redirectTo) {
  client.writeData({
    data: {
      loginPopUp: {
        __typename: 'LoginPopUp',
        open: false,
        title: '',
        prompt: '',
        redirectTo: '',
        showMembership: false,
      },
    },
  });

  const loginUrl = loginUrlLink;
  const { campaignCode = null } = router.query;

  let returnUrl = window.location.href;

  if (redirectTo) {
    returnUrl = `${window.location.origin}${redirectTo}`;
    const hasQueryString = returnUrl.includes('?');

    if (campaignCode) {
      returnUrl += `${hasQueryString ? '&' : '?'}campaignCode=${campaignCode}`;
    }
  }

  const href = appendQuery(loginUrl, {
    ReturnUrl: encodeURIComponent(returnUrl),
    UseReturnURL: 'true',
  });

  navigateTo(href, { type: POST_TYPES.LOGIN });
}
function LoginPopUp(props) {
  const [isLoading, setIsLoading] = useState(false);
  let membership = false;
  let renew = false;
  let canProceedToBooking = false;
  let membershipExpired = false;
  let showLogin = true;

  if (props.userStatus?.user) {
    membershipExpired = props.userStatus.user.membershipExpired;
    canProceedToBooking = props.userStatus.user.canProceedToBooking;
    membership = !canProceedToBooking && membershipExpired;
    renew = !canProceedToBooking && membershipExpired;
    showLogin = !isLoggedIn();
  }

  const {
    client,
    router,
    config,
    redirectTo,
    showMembership,
    quote,
    updateQuote,
    closePopup,
  } = props;
  const membershipByDD = getENumWithValue(quoteMembershipTypes.MembershipByDD);
  const { LoginUrl: loginUrl } = getConfig().publicRuntimeConfig.ENVIRONMENT;

  useEffect(() => {
    if (isLoading && quote?.membershipType === membershipByDD) {
      setIsLoading(false);
      closePopup();
    }
  }, [isLoading, closePopup, quote]);

  const handleAddMembership = async () => {
    const { product } = quote ?? {};
    const productCode = getProductCodeByName(
      product,
      config.configuration?.products,
    );

    const payload = {
      membershipType: getENumWithValue(quoteMembershipTypes.MembershipByDD),
      productCode,
    };
    setIsLoading(true);
    await updateQuote(payload);
  };

  const notLoggedInMessage = showMembership ?
    dictionaryItem('LoginPopUp', 'PromptLogin') : dictionaryItem('LoginPopUp', 'PromptLogin', 'HideMembership');

  return (
    <Fragment>
      <AlertIcon icon={svgError} />
      {props.title ? (
        <Title
          align="center"
          tag={3}
          size={1}
          marginBottom
        >
          {props.title}
        </Title>
      ) : (
        <Title
          align="center"
          dictionary={dictionaryItem('LoginPopUp')}
          tag={3}
          size={1}
          marginBottom
        />
      )}

      {props.prompt ? (
        <Text
          align="center"
          size="1rem"
        >
          {props.prompt}
        </Text>
      ) : (
        <>
          {/* is not logged in */}
          {!isLoggedIn() &&
            <Text align="center" dictionary={notLoggedInMessage} size="1rem" />
          }
          {/* is logged in but membership expired */}
          {membership &&
            <Text align="center" dictionary={dictionaryItem('LoginPopUp', 'PromptLogin', 'Renew')} size="1rem" />
          }
          {/* is logged in but does not have membership */}
          {renew &&
            <Text align="center" dictionary={dictionaryItem('LoginPopUp', 'PromptLogin', 'Membership')} size="1rem" />
          }
        </>
      )}

      <Actions>
        {showLogin &&
          <Action
            onClick={() => handleLoginButton(client, router, loginUrl, redirectTo)}
            dictionary={dictionaryItem('LoginPopUp', 'Login')}
            {...testingAttr('popup-login-button')}
          />
        }
        {showMembership &&
          <Action
            dictionary={dictionaryItem('LoginPopUp', 'AddMembership')}
            onClick={handleAddMembership}
            isLoading={isLoading}
            disabled={isLoading}
            {...testingAttr('popup-addMembership-button')}
          />
        }
      </Actions>
      {showMembership &&
        <Footer align="left">
          <Title
            dictionary={dictionaryItem('LoginPopUp', 'MemberDetails')}
            tag={4}
            size={4}
            marginBottom
          />
          <RichText dictionary={dictionaryItem('LoginPopUp', 'MemberDetails')} />
        </Footer>
      }
    </Fragment>
  );
}

LoginPopUp.propTypes = propTypes;
LoginPopUp.defaultProps = defaultProps;

export default compose(
  withApollo,
  withRouter,
  graphql(UPDATE_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(GET_CONFIGURATION, {
    name: 'config',
  }),
  graphql(GET_USER_STATUS, {
    name: 'userStatus',
    options: {
      fetchPolicy: FetchPolicy.NETWORK_ONLY,
    },
    skip: props => !isLoggedIn(),
  }),
)(LoginPopUp);
