import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const DisplayToRootContext = createContext({
  children: null,
  setChildren: () => null,
});

export const DisplayToRootProvider = ({ children: _children }) => {
  const [children, setChildren] = useState(null);

  return (
    <DisplayToRootContext.Provider value={{ children, setChildren }}>
      { _children }
    </DisplayToRootContext.Provider>
  );
};

DisplayToRootProvider.propTypes = {
  children: PropTypes.node,
};

DisplayToRootProvider.defaultProps = {
  children: null,
};
