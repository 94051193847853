import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import PopperJS from 'popper.js';

import { LazyAnchor } from './Popper.style';

class Popper extends React.Component {
  static propTypes = {
    anchor: PropTypes.shape({
      current: PropTypes.shape({}),
    }),
    children: PropTypes.func.isRequired,
    disablePortal: PropTypes.bool,
    lazyAnchor: PropTypes.bool,
    options: PropTypes.shape({
      modifiers: PropTypes.shape({}),
    }),
    open: PropTypes.bool,
    placement: PropTypes.string,
    portal: PropTypes.shape({}), // for now, is HtmlElement but needs to run on SSR.
  };

  static defaultProps = {
    anchor: {
      current: null,
    },
    lazyAnchor: false,
    disablePortal: false,
    open: false,
    options: {},
    placement: 'bottom',
    portal: null,
  };

  ref = createRef();

  refAnchor = this.props.anchor ? this.props.anchor.current : window.document.querySelector('body');

  state = {};

  componentDidMount() {
    this.handleOpen();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && !this.props.open) {
      // Otherwise handleExited will call this
      this.handleClose();
    }

    // Let's update the popper position
    if (
      prevProps.anchor !== this.props.anchor ||
      prevProps.open !== this.props.open ||
      prevProps.options !== this.props.options ||
      prevProps.disablePortal !== this.props.disablePortal ||
      prevProps.placement !== this.props.placement
    ) {
      this.handleOpen();
    }
  }

  componentWillUnmount() {
    if (!this.popper) return;
    this.popper.destroy();
  }

  handleOpen = () => {
    const {
      open,
      options,
      placement,
      disablePortal,
    } = this.props;

    const el = this.ref.current;

    const anchorEl = this.props.anchor ? this.props.anchor.current : this.refAnchor.current;

    if (!el || !anchorEl || !open) return;

    // Destroy open popper
    this.handleClose();

    this.popper = new PopperJS(anchorEl, el, {
      placement,
      ...options,
      modifiers: {
        ...(disablePortal
          ? {}
          : {
            // It's using scrollParent by default, we can use the viewport when using a portal
            preventOverflow: {
              boundariesElement: 'window',
            },
          }),
        ...options.modifiers,
      },
      // We could have been using a custom modifier like react-popper is doing
      // But it seems this is the best public API for this use case
      onCreate: this.handlePopperUpdate,
      onUpdate: this.handlePopperUpdate,
    });
  };

  handlePopperUpdate = ({ placement }) => {
    if (placement === this.state.placement) return;
    this.setState({ placement });
  };

  handleClose = () => {
    if (!this.popper) return;

    this.popper.destroy();
    this.popper = null;
  };

  render() {
    const { children, disablePortal, open } = this.props;
    if (!open) return null;

    return (
      <>
        {/*
          This is making a placeholder next to clicked div,
          so then portal element can anchor to it.
        */}
        {this.props.lazyAnchor &&
          <LazyAnchor ref={this.refAnchor.current} />
        }

        {!disablePortal && createPortal(
          children({
            forwardedRef: this.ref,
            updatePopper: () => {
              if (!this.popper) return;
              this.popper.update();
            },
          }),
          this.props.portal || window.document.getElementById('__next'),
        )}

        {disablePortal && children({
          forwardedRef: this.ref,
          updatePopper: () => {
            this.popper.update();
          },
        })}
      </>
    );
  }
}

export default Popper;
