import { getCookie } from './getReadableCookieValue';
import { RENEWAL_STATUSES } from '../config/membershipStatus';

const isLoggedIn = () => getCookie('isLoggedIn').indexOf('true') > -1;

// Logged in and has membership and is not a cancelled member
export const isLoggedInMember = (user) => (
  isLoggedIn()
  && user
  && !user.membershipExpired
  && !user.membershipNonMember // cancelled members are membershipNonMember
);

export const isLoggedInMemberOrInRenewal = (user) => (
  isLoggedInMember(user) || RENEWAL_STATUSES.includes(user?.membershipStatus)
);

export default isLoggedIn;
