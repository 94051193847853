import React, { useRef, createRef, useState } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';

import {
  AccordionButton,
  AccordionContent,
  AccordionHeading,
  Text,
  Footer as FooterStyled,
} from './HeaderAndFooter.style';
import TrustPilot from './TrustPilot';

function HeaderAndFooter({
  footerConfiguration,
  footerLinks,
  sections,
}) {
  const accordionRefs = useRef(sections.map(() => createRef()));
  const [accordionStates, setAccordionStates] = useState(sections.map(() => (false)));
  const { logoUrl, logoUrlLink } = footerConfiguration ?? {};

  const toggleAccordion = (accordionIndex) => {
    const isOpen = accordionStates[accordionIndex];
    const container = accordionRefs.current[accordionIndex].current;
    if (isOpen) {
      container.style.height = '0';
    } else {
      container.style.height = 'auto';
      const height = container.offsetHeight;
      container.style.height = `${0}px`;
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(container).height; // force reflow for transition
      container.style.height = `${height}px`;
    }

    setAccordionStates((states) => {
      const updatedState = [...states];
      updatedState.splice(accordionIndex, 1, !isOpen);
      return updatedState;
    });
  };

  return (
    <FooterStyled className="footer type--dreadnaught" role="contentinfo" id="footer" data-require="src/footer">
      <div className="footer__inner">
        <div className="footer__grid grid-container">
          <div className="footer__x grid-x grid-margin-x">
            <div className="footer__logo cell">
              <a href={logoUrlLink || '/'}>
                <img src={logoUrl} className="footer__logo-img" alt="The Caravan Club" />
              </a>
            </div>
            {sections.map((section, index) => (
              <div className="footer__column medium-4 cell" key={section.heading}>
                <AccordionButton type="button" onClick={() => toggleAccordion(index)}>
                  <AccordionHeading isOpen={accordionStates[index]} className={`footer__column-heading type--leviathan${accordionStates[index] ? ' footer__column-heading--open' : ''}`}>
                    {section.heading}
                  </AccordionHeading>
                </AccordionButton>
                <AccordionContent className="footer__column-content richtext" ref={accordionRefs.current[index]}>
                  <span dangerouslySetInnerHTML={{ __html: section.content }} />
                  {index === 0 && (
                    <TrustPilot />
                  )}
                </AccordionContent>
              </div>
            ))}

            <div className="footer__base cell">
              <Text className="footer__base-copyright" dictionary={dictionaryItem('Footer', 'Copyright')} />
              <ul className="footer__base-links">
                {footerLinks.map(link => (
                  <li className="footer__base-links-item" key={link.href}>
                    <a href={link.href} target={link.target} title={link.title}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
}

HeaderAndFooter.propTypes = {
  footerConfiguration: PropTypes.shape({
    logoUrl: PropTypes.string,
    logoUrlLink: PropTypes.string,
  }).isRequired,
  footerLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default HeaderAndFooter;
