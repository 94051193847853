import styled, { css } from 'styled-components';

const sizes = [
  '3rem',
  '1.375rem',
  '1.25rem',
  '1.125rem',
  '1rem',
  '0.875rem',
];

const Title = css`
  ${({ align }) => align && css`text-align: ${align};`}
  color: ${({ color, theme }) => color || theme.COLOR_TEXT_HEADING};
  font-size: ${({ size }) => sizes[size] || size};
  font-family: ${({ theme }) => theme.TEXT_HEADING_FONT};
  font-weight: 600;
  line-height: 1.25em;
  margin: 0;
  ${({ marginBottom }) => marginBottom &&
    css`margin-bottom: ${typeof marginBottom === 'string' ? marginBottom : '1em'};`}
  ${({ marginTop }) => marginTop &&
    css`margin-top: ${typeof marginTop === 'string' ? marginTop : '1em'};`}
`;

export default styled.h1`
  ${Title}
`;

export const Title1 = styled.h2`
  ${Title}
`;

export const Title2 = styled.h3`
  ${Title}
`;

export const Title3 = styled.h4`
  ${Title}
`;

export const Title4 = styled.h5`
  ${Title}
`;

export const TitleAsDiv = styled.div`
  ${Title}
`;
