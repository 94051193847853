import React from 'react';
import PropTypes from 'prop-types';

import withDictionary from '../../../hocs/withDictionary';

import StyledTitle, {
  Title1,
  Title2,
  Title3,
  Title4,
  TitleAsDiv,
} from './Title.style';

const propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  'data-testid': PropTypes.string,
  marginBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  marginTop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

const defaultProps = {
  align: '',
  children: null,
  color: '',
  'data-testid': '',
  marginBottom: false,
  marginTop: false,
  size: 0,
  tag: 0,
  className: '',
};

function Title({
  align,
  children,
  className,
  color,
  marginBottom,
  marginTop,
  size,
  tag,
  ...props
}) {
  const titles = [
    StyledTitle,
    Title1,
    Title2,
    Title3,
    Title4,
  ];

  const titlesAsTag = {
    // add more as needed
    div: TitleAsDiv,
  };

  let titleAsComponent;
  if (typeof tag === 'string') {
    titleAsComponent = titlesAsTag[tag];
    if (!titleAsComponent) {
      console.error(`Title component does not currently support tag ${tag}, supported tags are ${Object.keys(titlesAsTag).join(',')} or use an integer to represent h tag number`);
    }
  }
  const Component = titleAsComponent || titles[tag];

  return (
    <Component
      align={align}
      color={color}
      className={className}
      data-testid={props['data-testid']}
      marginBottom={marginBottom}
      marginTop={marginTop}
      size={size}
    >
      {children}
    </Component>
  );
}

Title.defaultProps = defaultProps;
Title.propTypes = propTypes;

export default withDictionary(Title, { name: 'Title' });
