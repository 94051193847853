import { useCallback, useState } from 'react';
import { unionBy } from 'lodash';

import alwaysArray from '../alwaysArray';

function useComponentError() {
  const [errorState, setErrorState] = useState(null);

  const getComponentError = useCallback(
    errorKey => (errorState ? errorState[errorKey] : null),
    [errorState],
  );

  const handleError = (payload, componentName) => {
    const componentErrors = unionBy(alwaysArray(payload), 'message');

    const errors = {
      ...(errorState || {}),
      ...(componentName ? { [componentName]: componentErrors } : {}),
    };

    setErrorState(errors);

    return payload;
  };

  /**
   * Assigns the error handler with a component name so you don't have to
   * pass it as an argument every time.
   */
  const handleComponentError =
    componentName => payload => handleError(payload, componentName);

  return {
    componentErrors: errorState,
    getComponentError,
    onComponentError: handleComponentError,
    onError: handleError,
  };
}

export default useComponentError;
