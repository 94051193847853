import { getPhase, PHASE_1 } from './phase';

const MEMBERSHIP_STATUS = {
  UNDETERMINED: 0,
  ACTIVE: 1,
  ADVANCE_CANCELLATION: 2,
  CANCELLED: 3,
  INVITE_TO_RENEW: 4,
  REFERRED: 5,
  REMINDER_1: 6,
  REMINDER_2: 7,
  RENEWAL_PENDING: 8,
  SUSPENDED: 9,
  REJECTED: 10,
};

export const ALLOWED_STATUSES = [
  MEMBERSHIP_STATUS.ACTIVE,
  MEMBERSHIP_STATUS.INVITE_TO_RENEW,
  MEMBERSHIP_STATUS.RENEWAL_PENDING,
];

export const DISALLOWED_STATUSES = [
  MEMBERSHIP_STATUS.CANCELLED,
  MEMBERSHIP_STATUS.SUSPENDED,
  MEMBERSHIP_STATUS.REFERRED,
];

export const RENEWAL_STATUSES = getPhase() === PHASE_1 ? [
  MEMBERSHIP_STATUS.CANCELLED,
  MEMBERSHIP_STATUS.REMINDER_1,
  MEMBERSHIP_STATUS.REMINDER_2,
] : [
  MEMBERSHIP_STATUS.REMINDER_1,
  MEMBERSHIP_STATUS.REMINDER_2,
];

export default MEMBERSHIP_STATUS;
