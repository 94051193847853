import React, { useEffect, useRef } from 'react';
import getConfig from 'next/config';

const {
  TRUST_PILOT_TEMPLATE_ID,
  TRUST_PILOT_BUSINESS_UNIT_ID,
  ENVIRONMENT,
} = getConfig().publicRuntimeConfig;

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      {(TRUST_PILOT_TEMPLATE_ID && TRUST_PILOT_BUSINESS_UNIT_ID) && (
        <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget" // Renamed this to className.
          style={{ float: 'left', right: '1rem' }}
          data-locale="en-GB"
          data-template-id={TRUST_PILOT_TEMPLATE_ID}
          data-businessunit-id={TRUST_PILOT_BUSINESS_UNIT_ID}
          data-style-height="24px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a href={`https://www.trustpilot.com/review/${ENVIRONMENT.HOMEPAGE_URL}`} target="_blank" rel="noreferrer">
            Trustpilot
          </a>
        </div>
      )}
    </>
  );
};

export default TrustBox;
