import theme from '../../styles/config/theme';

// deprecated
export function isDesktopOrSmaller() {
  if (!process.browser) return false;
  return window.innerWidth < theme.DESKTOP_LARGE;
}

export function isDesktopOrBigger() {
  if (!process.browser) return false;
  return window.innerWidth >= theme.DESKTOP;
}

export function isTabletOrSmaller() {
  if (!process.browser) return false;
  return window.innerWidth < theme.DESKTOP;
}

export function isMobileOrSmaller() {
  if (!process.browser) return false;
  return window.innerWidth < theme.TABLET;
}
