import React, {
  Children, Component, cloneElement,
} from 'react';
import getConfig from 'next/config';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Error from 'next/error';
import { withRouter } from 'next/router';

import isLoggedIn from '../../../lib/isLoggedIn';
import liveChat from '../../../lib/liveChat';

import ErrorProvider from '../../../context/error-context';

import HeaderAndFooterLayout from '../HeaderAndFooterLayout';

import StyledAppWrapper, { Main } from '../Standard/index.style';
import { LoadingContainer } from './index.style';

import fontFace from '../../../styles/libs/fontFace';

import theme from '../../../styles/config/theme';

import GET_LAYOUT_CONFIG from '../graphql/getLayoutConfig';
import GET_USER from '../../../config/graphql/getUser';
import LoadingSpinner from '../../ui/Loading/LoadingSpinner';
import IbePropTypes from '../../../IbePropTypes';
import MEMBERSHIP_STATUS from '../../../config/membershipStatus';
import ROUTES from '../../../constants/routes';
import FetchPolicy from '../../../constants/FetchPolicy';
import { checkWebview } from '../../../lib/helpers/mobileApp';
import { navigateTo } from '../../../lib/helpers/navigation';
import { PopupsProvider } from '../../../context/Popups';
import { DisplayToRootProvider } from '../../../context/DisplayToRootProvider';
import logToCasClientSide, { AuditType, safelyToString } from '../../../lib/logToCasClientSide';
import getFetchPolicyWithExpiry from '../../../lib/getFetchPolicyWithExpiry';

// Any global styles should go here
const GlobalStyle = createGlobalStyle``;

// Fonts need to be appended to prevent further requests
const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.innerHTML = `
  ${fontFace({ name: 'Elliot', src: 'SElliotWeb-Regular' })}
  ${fontFace({ name: 'ElliotBold', src: 'FSElliotWeb-Bold', fontWeight: 'bold' })}
  ${fontFace({ name: 'ElliotItalic', src: 'FSElliotWeb-Italic', fontStyle: 'italic' })}
  ${fontFace({
    name: 'ElliotBoldItalic',
    src: 'FSElliotWeb-BoldItalic',
    fontStyle: 'italic',
    fontWeight: 'bold',
  })}
  `;
  head.appendChild(style);
};

class AppWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    layout: PropTypes.shape({}),
    error: PropTypes.shape({
      networkError: IbePropTypes.networkError,
    }),
    layoutLoading: PropTypes.bool,
    layoutError: PropTypes.shape({}),
    user: PropTypes.shape(IbePropTypes.user),
    refetch: PropTypes.func,
    router: PropTypes.shape(IbePropTypes.router),
  }

  static defaultProps = {
    layout: undefined,
    error: undefined,
    layoutLoading: false,
    layoutError: undefined,
    refetch: undefined,
    router: undefined,
    user: undefined,
  }

  constructor(props) {
    super(props);

    this.isLoggedIn = isLoggedIn();
  }

  componentDidMount() {
    // Only enable scroll to zoom on production builds and non webview
    if (process.env.NODE_ENV === 'production' && !checkWebview()) {
      liveChat();
    }

    loadFonts();
    this.checkMembership();

    if (window && window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }

    // TODO: Enable service worker
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/serviceWorker.js')
    //     .catch(err => console.error(`--- Service worker failed: ${err}`));
    // } else {
    //   console.warn('Service worker not supported');
    // }

    window.addEventListener('focus', this.onWindowFocus);
  }

  componentDidUpdate() {
    this.getAndUpdateIsLoggedIn();
    this.checkMembership();
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onWindowFocus);
  }

  getAndUpdateIsLoggedIn = () => {
    this.isLoggedIn = isLoggedIn();
    return this.isLoggedIn;
  }

  onWindowFocus = () => {
    const previouslyLoggedIn = this.isLoggedIn && !this.getAndUpdateIsLoggedIn();
    if (this.props.refetch && this.getAndUpdateIsLoggedIn()) {
      this.props.refetch();
    } else if (previouslyLoggedIn && this.props.router) {
      this.props.router.reload();
    }
  }

  checkMembership = () => {
    const { user } = this.props;

    // rejected and suspended are treated the same
    if (isLoggedIn() &&
      (user?.membershipStatus === MEMBERSHIP_STATUS.SUSPENDED
        || user?.membershipStatus === MEMBERSHIP_STATUS.REJECTED)
      && !window.location.href?.includes(ROUTES.suspendedMember)
    ) {
      const query = QueryString.stringify(window.location.search);
      const ReturnUrl = `${window.location.href.split('?')[0]}?${query}`;

      navigateTo(ROUTES.suspendedMember, {
        query: {
          ReturnUrl,
        },
      });
    }
  }

  renderContent = () => {
    const { publicRuntimeConfig } = getConfig();
    const {
      children,
      error,
      layoutLoading,
      layout,
      router: { query },
    } = this.props;
    // only show the loader if there is no layout data, there will be data, if its loaded before,
    // we don't want a white screen white it reloads
    if (layoutLoading && !layout) {
      return (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      );
    }

    if (error) {
      console.error('AppWrapper.renderContent() error:', error);
      return <Error statusCode={error?.networkError?.statusCode} />;
    }

    return (
      <Main data-main isBookingWidget={query.bookingWidget === 'true'}>
        {Children.map(
          children, child => cloneElement(child, { publicRuntimeConfig }),
        )}
      </Main>
    );
  }

  renderHeaderFooterLayout = () => {
    const {
      layout,
      layoutError,
      layoutLoading,
      router,
    } = this.props;
    if (layoutLoading && !layout) return null;
    if (layoutError) {
      console.error('AppWrapper.renderHeaderFooterLayout() layoutError:', layoutError);
      return <Error />;
    }
    if (checkWebview(router.query) || router.query.bookingWidget === 'true') {
      return this.renderContent();
    }
    return (
      <HeaderAndFooterLayout
        config={layout}
        isLoggedIn={isLoggedIn()}
      >
        {this.renderContent()}
      </HeaderAndFooterLayout>
    );
  }

  render() {
    return (
      <ErrorProvider>
        <ThemeProvider theme={theme}>
          <PopupsProvider>
            <StyledAppWrapper data-app-wrapper>
              <DisplayToRootProvider>
                {this.renderHeaderFooterLayout()}
              </DisplayToRootProvider>
            </StyledAppWrapper>
            <GlobalStyle />
          </PopupsProvider>
        </ThemeProvider>
      </ErrorProvider>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(GET_LAYOUT_CONFIG, {
    options: () => {
      const fetchPolicy = getFetchPolicyWithExpiry('headerAndFooter', {
        defaultPolicy: FetchPolicy.CACHE_FIRST,
        expiry: 1000 * 60 * 5, // 5 minutes
        expiryPolicy: FetchPolicy.NETWORK_ONLY,
      });

      return { fetchPolicy };
    },
    props: ({ data }) => ({
      layout: data.layout,
      layoutError: data.error,
      layoutLoading: data.loading,
    }),
  }),
  graphql(GET_USER, {
    options: {
      fetchPolicy: FetchPolicy.NETWORK_ONLY, // the main API call for user data
      onError: (error) => {
        // get as much data as we can from the incoming error.
        let errorEventString;
        try {
          errorEventString = safelyToString(error);
        } catch (e) {
          errorEventString = '';
        }
        // Attempting to get a useful action name
        const action = error?.networkError?.response?.url || error?.networkError?.toString() || 'unknown error';
        logToCasClientSide(AuditType.Error, 'AppWrapper.getUser()', action, errorEventString);
      },
    },
    props: ({
      data,
    }) => ({
      user: data.user,
      error: data.error,
      loading: data.loading,
      refetch: data.refetch,
    }),
    skip: props => !isLoggedIn() || props.router.query?.bookingWidget === 'true',
  }),
)(AppWrapper);
