import gql from 'graphql-tag';

export default gql`
  query {
    user @rest(
      type: "MemberDetails",
      path: "User"
    ) {
      address {
        country
        county
        addressLines
        postCode
      }
      canProceedToBooking
      dateOfBirth
      email
      errorCode
      firstName
      hasAddress
      lastName
      membershipExpired
      membershipInRenewal
      membershipNonMember
      membershipNumber
      membershipStatus
      membershipSuspended
      name
      personId
      telephones {
        countryPrefix
        number
      }
      title
      warnings
    }
  }
`;
